import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './Layout3';

import * as serviceWorker from './serviceWorker';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

 // // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCz2kOWwV0MmCxhIzePJmpXym6C057GQRE",
    authDomain: "arsicault-bakery.firebaseapp.com",
    databaseURL: "https://arsicault-bakery.firebaseio.com",
    projectId: "arsicault-bakery",
    storageBucket: "arsicault-bakery.appspot.com",
    messagingSenderId: "1082721544000",
    appId: "1:1082721544000:web:b60bcf34bcbb176e08bca5",
    measurementId: "G-4YNRDFSSD6"
  };
  
  // // Initialize Firebase
  // eslint-disable-next-line
  const fbApp = initializeApp(firebaseConfig);
  const fbAnalytics = getAnalytics();
  logEvent(fbAnalytics, 'test log');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

