import React, { Component } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import logo from "./pin.png";

export class ABMapContainer extends Component {
    render() {
        const containerStyle = {
            position: "relative",
            paddingTop: '100%', /* 1:1 Aspect Ratio */
            minWidth: "300px",
        };

        const defaultZoom = 19;
        return (
            <Map google={this.props.google} zoom={this.props.zoom || defaultZoom} style={{ width: "100%", height: "100%" }} initialCenter={this.props.coords} containerStyle={containerStyle}>
                <Marker title={this.props.tooltip} name={this.props.name} position={this.props.coords} icon={{ url: logo, scaledSize: new this.props.google.maps.Size(20, 33) }} />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyC-nI1z3FGBwZlaUSwyoboCFJIBXj24mJo"
})(ABMapContainer);



// https://maps.googleapis.com/maps/api/place/queryautocomplete/json?key=AIzaSyC-nI1z3FGBwZlaUSwyoboCFJIBXj24mJo&input=Arsicault+Bakery
// https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJJ09d9DmHhYARkrToDt9JCy8&fields=name,place_id,rating,formatted_phone_number,website,formatted_address,geometry,vicinity&key=AIzaSyC-nI1z3FGBwZlaUSwyoboCFJIBXj24mJo
// https://maps.googleapis.com/maps/api/place/queryautocomplete/json?key=AIzaSyC-nI1z3FGBwZlaUSwyoboCFJIBXj24mJo&input=Arsicault+Civic+Center
// https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJ90JImRKBhYARXrwUAyZIhqw&fields=name,place_id,rating,formatted_phone_number,website,formatted_address,geometry,vicinity&key=AIzaSyC-nI1z3FGBwZlaUSwyoboCFJIBXj24mJo

