import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const MenuListing = (props) => {
  return (
    <Container className="no-margin no-padding">
      <Row className="pt-4">
        <Col>
          <h2>{props.title}</h2>
        </Col>
      </Row>
      {props.items.map((item, index) => (
        <Row key={index} className="pt-2">
          <Col xs={9}>{item[0]}</Col>
          <Col xs={3}>{item[1]}</Col>
        </Row>
      ))}
    </Container>
  );
};

const ABArguelloMenu = () => {
  const menu = {
    left: [{
        title: "Daily Pastries",
        items: [
          ["Plain Croissant", "4.75"],
          ["Chocolate Croissant", "5.75"],
          ["Ham & Cheese Croissant", "6.75"],
          ["Almond Croissant", "5.75"],
          ["Chocolate Almond Croissant", "6.5"],
          ["Kouign Amann", "5"],
          ["Morning Bun", "5"],
          ["Savory Scone", "5.25"],
          ["Cranberry Coconut Scone", "4.25"],
          ["Currant Scone", "4.25"],
          ["Chocolate Chip Cookie", "3.75"],
        ],
      },
    ],
    right: [{
        title: "Small Bites",
        items: [
          ["Financier", "12"],
          ["Shortbread Cookies", "10"],
          ["Chocolate Shortbread Cookies", "12"],
          ["Caramelized Puff Pastry", "10"],
        ],
      }, {
        title: "Quiche",
        items: [
          ["Refrigerated Whole Bacon Onion", "72"],
          ["Refrigerated Whole Mushroom", "72"]

        ],
      }, {
        title: "Hot Drinks",
        items: [
          ["Drip Coffee", "2.75 / 3.25"],
          ["Tea", "2.75"],
        ],
      },
    ],
  };

  return (
    <div className="grid-container grid-container--fit">
      <div className="grid-element">
        {menu.left.map((section, item) => (
          <MenuListing key={item} title={section.title} items={section.items} />
        ))}      </div>
      <div className="grid-element">
        {menu.right.map((section, item) => (
          <MenuListing key={item} title={section.title} items={section.items} />
        ))}
      </div>
    </div>
  );
};

export default ABArguelloMenu;
