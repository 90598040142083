import React from "react";
import { withRouter } from "react-router";
import "./ABFooter.scss";

const ABFooter = (props) => {
    return (
        <footer className={`footer ${props.location.pathname === '/home'? 'text-white' : 'text-black'}`}>
            <span className="arsicault-title">Arsicault</span> <span>&copy; 2020 Arsicault Bakery</span>
        </footer>
    );
};

export default withRouter(ABFooter);
