import React from 'react';
import "./ABHome.scss"
import arguello from './imgs/carousel/1-min.jpeg'
import almond from './imgs/carousel/3-min.jpeg'
import morningbun from './imgs/carousel/4-min.jpeg'
import ham from './imgs/carousel/5-min.jpeg'
//import scones from './imgs/carousel/6-min.jpeg'
import mcallister from './imgs/carousel/7-min.jpg'
import plain_and_chocolate from './imgs/carousel/plain_and_chocolate.min.jpg'
import blackberry from './imgs/carousel/blackberry.min.jpg'
import artsy_quiche from './imgs/carousel/artsy_quiche.min.jpg'
import baguette from './imgs/carousel/baguette.jpg'
import port_baguette from './imgs/carousel/port-baguette.jpg'
import inside_store from './imgs/carousel/inside-store.jpg'
import apple_turnover from './imgs/carousel/apple_turnover.min.jpg'

class ABHome extends React.Component {
  constructor(){
    super()

    this.state ={
      images_small:[arguello, mcallister,plain_and_chocolate, almond, port_baguette, ham, morningbun],
      images_big:[arguello, mcallister, inside_store, apple_turnover, artsy_quiche, baguette, blackberry],
      screenIsWide:  window.innerWidth > 991,
      display: 3,
      isLoading: false,
      loadedCount: 0
    }
    
    // this.loading = setInterval(()=>{
    //   if(this.state.loadedCount === this.state.images.length){
    //     this.setState({...this.state, isLoading:false})
    //     clearInterval(this.loading);
    //   }
        
    // },500)

    // for(let img of this.state.images){
    //   const image = new Image();
    //   image.onload = () => { 
    //     this.setState({...this.state, loadedCount: this.state.loadedCount+1}) 
    //   }
    //   image.src = `${img}`;
    // }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({...this.state,  screenIsWide: window.innerWidth > 991 });
  }

  render() {
    const images = this.state.screenIsWide ? this.state.images_big : this.state.images_small
    return (
          <div className="home-container" >
            {images.map((image, index)=>
              <span key={index} className={`background-image-fullscreen ${this.state.isLoading? '' : 'animate'}`} style={{backgroundImage:`url("${image}")`, animationDelay: `${index*this.state.display}s`}}></span>
            )}
          </div>
    );
  }
}

export default ABHome;
