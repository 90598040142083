import React from 'react';
import './ABGiftCards.scss';
import giftcard from './imgs/gc.jpg'

const ABGiftCards = () => {
    return (
        <div className="split-view content-item">
            <div>
            <h2>Gift Cards</h2>
                <br/>
                <p>Physical Gift Cards are Available in Store*</p>
                <p>Physical Gift Cards require a minimum balance of $25 </p>
                
                <p>* We highly encourage you to only purchase gift cards directly from us. 
                    They are managed by Square and other resellers are unauthorized for sale and possibly selling fraudulent.</p>

            </div>
            <div className="gc-card">
               <img src={giftcard} className="gift-card" style={{borderRadius: '20px'}} alt="Gift Card"/>
            </div>
    </div>
    )
}

export default ABGiftCards