import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Animated } from "react-animated-css";
import ABCover from "./ABCover";
import ABNavBar from "./ABNavBar";
import ABHome from "./ABHome";
import ABMenus from "./ABMenus";
import ABLocations from "./ABLocations";
import ABGiftCards from "./ABGiftCards";
import ABOrders from "./ABOrders";
import ABCareers from "./ABCareers";
import ABMedia from "./ABMedia";
import ABFooter from "./ABFooter";
import Modal from 'react-bootstrap/Modal';


function App() {
    const firstVisit = sessionStorage.getItem("returning") === null;
    const [showCover, setShowCover] = useState(firstVisit);

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        setTimeout(() => {
            setShowCover(false);
            sessionStorage.setItem("returning", "true");
        }, 2000);
    });
    return (
        <React.Fragment>
            <Router>
                {showCover && <ABCover />}

                {/* <Modal show={show && !showCover} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                                <h5 style={{margin:"0", fontWeight:"bold"}}>McAllister Renovation Closure</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{textAlign:"center", fontWeight:"bold", color:"red", fontSize:"19px"}}>Our McAllister location will be closed May 15th for Renovation.</div>
                    </Modal.Body>
                </Modal> */}

                <Animated className={showCover ? "hidden" : "appContainer"} animationIn="fadeIn" animationInDuration={1000} animateOnMount={false} isVisible={!showCover}>
                        <ABNavBar />

                        <main className="content">
                            <Switch>
                                <Route exact path="/home" children={<ABHome />} />
                                <Route exact path="/locations" children={<ABLocations />} />
                                <Route exact path="/menus" children={<ABMenus />} />
                                <Route exact path="/giftcards" children={<ABGiftCards />} />
                                <Route exact path="/orders" children={<ABOrders />} />
                                <Route exact path="/careers" children={<ABCareers />} />
                                <Route exact path="/media" children={<ABMedia />} />
                                {/* <Route exact path="/seasonal" children={<ABSeasonal />} /> */}
                                <Route path="*"><Redirect to="/home" /></Route>
                            </Switch>
                        </main>

                        <ABFooter/>
                </Animated>
            </Router>
        </React.Fragment>
    );
}

export default App;
