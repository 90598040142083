import React from 'react';
import './ABCareers.scss';
import careerpic from './imgs/crs.png'

const ABCareers = () => {
    return (
        <div className="split-view content-item">
            <div>
                <h2>Work At Arsicault!</h2>
                <br />
                <p>We are always looking for talented bakers for whom quality is everything. Please email a copy of your resume to <a href='mailto:info@arsicault-bakery.com'>info@arsicault-bakery.com</a> </p>
                <p>We also welcome individuals interested in FOH positions. Make people smile, make them a great latte, and you could be one of them!</p>
            </div>
            <div>
                <img src={careerpic} className="careers-img" style={{ borderRadius: '20px' }} alt="Gift Card" />
            </div>
        </div>
    )
}

export default ABCareers