import React from 'react';
import './ABCover.scss';

const ABCover = () => {
    return (
        <div className='ABCoverImgContainer'>
            <div className='ABCoverImg'></div>
        </div>
    )
}

export default ABCover;
