import React from "react";
import "./ABMenus.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ABArguelloMenu from "./ABArguelloMenu";
import ABMcAllisterMenu from "./ABMcAllisterMenu";

const ABMenus = () => {
    return (
        <div className="menu-container content-item">
            <Tabs defaultActiveKey="arguello" style={{ flex: 1, justifyContent: "space-around", flexDirection: "row" }}>
                <Tab className="pt-3" eventKey="arguello" title="Arguello Menu">
                    <ABArguelloMenu />
                </Tab>
                <Tab className="pt-3" eventKey="mcallister" title="McAllister Menu">
                    <ABMcAllisterMenu />
                </Tab>
            </Tabs>
        </div>
    );
};

export default ABMenus;
