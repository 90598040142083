import React from "react";
import "./ABNavBar.scss";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "./imgs/circle_logo.png";
// import logo from "./imgs/pride_circle_logo.jpeg";

import { withRouter } from "react-router";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ABNavBar = (props) => {
    return (
        <header className={`${props.location.pathname === '/home' ? "header-home" : "header"}`}>
            
            <NavLink to="/home" className="logo-container"><img src={logo} className="logo" alt="Arsicault Logo" /></NavLink>            
            <Navbar collapseOnSelect expand="lg" >
            {/* <span className="header-alert"> McAllister Closed until Monday, Feb 1st</span> */}

                <Nav>
                    <Nav.Item><Nav.Link eventKey="0" as={NavLink} to="/home">Home</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="1" as={NavLink} to="/locations">Locations</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="2" as={NavLink} to="/menus">Menus</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="3" as={NavLink} to="/giftcards">Gift Cards</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="4" as={NavLink} to="/orders">Orders</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="5" as={NavLink} to="/careers">Careers</Nav.Link></Nav.Item>
                    {/* <Nav.Item><Nav.Link eventKey="4" as={NavLink} to="/seasonal">Seasonal</Nav.Link></Nav.Item> */}
                    <Nav.Item><Nav.Link eventKey="5" as={NavLink} to="/media">Media</Nav.Link></Nav.Item>
                    <a className="instagram-container" href="https://www.instagram.com/arsicaultbakery" target="_blank" rel="noopener noreferrer">
                        <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="instagram-pt2" icon={faInstagram} />
                        <FontAwesomeIcon className="instagram-pt1" icon={faInstagram} />
                        </span>         
                    </a>
                </Nav>
                  </Navbar>

        </header>
    );
};

export default withRouter(ABNavBar);

