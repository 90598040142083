import React from 'react';
import './ABMedia.scss';

const ABMedia = () => {

    return (
        <div className="media-container">

            <div className="media-card">
                <div className="aspect-ratio">
                    <iframe className="media-item" src="https://www.youtube-nocookie.com/embed/nsYEJnRCWMM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen></iframe>
                </div>
                <hr />
                <div className="media-caption">
                    <p>January 30th, 2023</p>
                    <p className="media-caption">Arsicault Bakery on KPIX - National Croissant Day</p>
                </div>
            </div>

            <div className="media-card">
                <div className="aspect-ratio">
                    <iframe className="media-item" src="https://www.youtube-nocookie.com/embed/NvQIfR1n3Rk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen></iframe>
                </div>
                <hr />
                <div className="media-caption">
                    <p>June 20th, 2022</p>
                    <p>Arsicault Bakery featured on KQED Food - Check, Please! Bay Area</p>
                </div>
            </div>

        </div>
    )
}

export default ABMedia